<template>
  <div class="outer-wrapper">
    <!--<div class="img-wrapper" v-for="(ele,index) in imgList" :key="index" :style="ele.style" @mouseover="hoverIndex=index" @mouseleave="hoverIndex=-1">
        <img :src="ele.url" alt="" class="img-item" @click="openD">
        <p v-show="hoverIndex===index" @click="openD" style="cursor:pointer;">{{ele.content}}</p>
        </div>
    <Modal
        title="客流数据专项分析"
        v-model="modalSwitch"
        :closable="false" width="800"
        :styles="{top: '5px'}">
        <div style="height:600px;"></div>
        
        <div slot="footer"></div>
    </Modal>-->

    <Collapse v-model="cosw">
      <Panel name="1">
        2020年安评数据
        <div slot="content">
          <Tabs>
            <TabPane label="换乘站在用运行图运能情况" name="name1">
              <div class="bb-outer-wrapper">
              <div class="img-bb">
                <div class="station-wrapper" v-for="(ele,index) in stationList" :key="index" :style="{top:ele.y,left:ele.x}">
                  <div class="station-inner-wrapper">
                    <img :src="ele.url" alt="">
                  <div v-html="ele.data" class="html-wrapper">
                  </div>
                  
                  </div>
                </div>
              </div>
              </div>
            </TabPane>
            <TabPane label="运能情况数据表" name="name2">
              <query-table
                :columnsFormatData="columnsFormatData"
                askData="transferList"
                :refreshData="update_count"
                main_width="1260px"
              ></query-table>
            </TabPane>
            <TabPane label="各线路高断面客流" name="name3"
              ><div
                style="
                  text-align: center;
                  padding: 40px 0;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                "
              >
                <div
                  v-for="(item, subIndex) in passenger"
                  :key="subIndex + 'i'"
                  style="margin: 30px"
                >
                  <i-circle
                    :size="200"
                    :trail-width="6"
                    :stroke-width="7"
                    :percent="percentCal(item.rate * 100)"
                    stroke-linecap="square"
                    :stroke-color="colorCal(item.rate * 100)"
                  >
                    <div class="demo-Circle-custom">
                      <p
                        style="font-size: 24px; line-height: 200%; color: #000"
                      >
                        {{ item.line_name }}
                      </p>
                      <p>断面客流量：{{ item.flow }}</p>
                      <p>计划运能：{{ parseInt(item.flow/item.rate) }}</p>
                      <p>
                        满载率：
                        <i>{{ Math.round(item.rate * 100) + "%" }}</i>
                      </p>
                    </div>
                  </i-circle>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Panel>
    </Collapse>
  </div>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";
let userToken = localStorage.getItem("current_user_token");
export default {
  name: "emergency",
  components: {
    queryTable,
  },
  data() {
    return {
      hoverIndex: -1,
      modalSwitch: false,
      cosw: [1],
      stationData:[],
      passenger: [
        { line_name: "1号线", flow: 48711,  rate: 1.11 },
        { line_name: "2号线", flow: 28582,  rate: 0.81 },
        { line_name: "3号线", flow: 27326,  rate: 0.81 },
        { line_name: "4号线", flow: 33827, rate: 0.97 },
        { line_name: "5号线", flow: 18517,  rate: 0.56 },
        { line_name: "7号线", flow: 26568,  rate: 0.79 },
        { line_name: "10号线", flow: 7230, rate: 0.37 },
        { line_name: "18号线", flow: 10100,  rate: 0.43 },
      ],
      imgList: [
        {
          url: process.env.BASE_URL + "img/flow/f1.jpg",
          content: "客流统计数据和列车运行计划资料",
          style: "top:40px;left:420px;",
        },
        {
          url: process.env.BASE_URL + "img/flow/f2.jpg",
          content: "换乘站的客流组织方案",
          style: "top:230px;left:150px;",
        },
        {
          url: process.env.BASE_URL + "img/flow/f3.jpg",
          content: "换乘站现场客流情况",
          style: "top:230px;left:670px;",
        },
        {
          url: process.env.BASE_URL + "img/flow/f4.jpg",
          content: "视频监控及现场联动情况",
          style: "top:420px;left:420px;",
        },
      ],
      update_count: 0,
      columnsFormatData: [
        {
          title: "换乘站名称",
          key: "station_name",
          align: "center",
        },
        {
          title: "换乘线路",
          key: "transfer_line",
          align: "center",
          width: 180,
        },
        {
          title: "换入客量",
          key: "in_amount",
          align: "center",
          width: 190,
        },
        {
          title: "运能",
          key: "capacity",
          align: "center",
          width: 150,
        },
        {
          title: "综合匹配比",
          key: "ratio",
          align: "center",
          width: 150,
        },
      ],
    };
  },
   computed:{
    stationList:function(){
      return [
        {name:"北站西二路",data:this.stationDict["北站西二路"],x:'588px',y:'220px',url: process.env.BASE_URL + "img/flow/北站西二路.png"},
        {name:"槐树店",data:this.stationDict["槐树店"],x:'782px',y:'426px',url: process.env.BASE_URL + "img/flow/槐树店.png"},
        {name:"成都东客站",data:this.stationDict["成都东客站"],x:'760px',y:'504px',url: process.env.BASE_URL + "img/flow/成都东客站.png"},
        {name:"市二医院",data:this.stationDict["市二医院"],x:'642px',y:'403px',url: process.env.BASE_URL + "img/flow/市二医院.png"},
        {name:"一品天下",data:this.stationDict["一品天下"],x:'430px',y:'302px',url: process.env.BASE_URL + "img/flow/一品天下.png"},
        {name:"神仙树",data:this.stationDict["神仙树"],x:'544px',y:'544px',url: process.env.BASE_URL + "img/flow/神仙树.png"},
        {name:"文化宫",data:this.stationDict["文化宫"],x:'402px',y:'370px',url: process.env.BASE_URL + "img/flow/文化宫.png"},
        {name:"春熙路",data:this.stationDict["春熙路"],x:'618px',y:'423px',url: process.env.BASE_URL + "img/flow/春熙路.png"},
        {name:"太平园",data:this.stationDict["太平园"],x:'438px',y:'518px',url: process.env.BASE_URL + "img/flow/太平园.png"},
        {name:"中医大省医院",data:this.stationDict["中医大省医院"],x:'480px',y:'370px',url: process.env.BASE_URL + "img/flow/中医大省医院.png"},
      ];
    },
    stationDict:function(){
      let tmp = {};
      for(let i of this.stationData){
        if(i.station_name.trim() in tmp){
          tmp[i.station_name.trim()] += "<p>"+i.transfer_line+"：</p><p style='text-indent:30px;'>换入客量："+i.in_amount+"</p>"+"<p style='text-indent:30px;'>运能："+i.capacity+"</p><p style='text-indent:30px;'>综合匹配比："+i.ratio+"</p>";
        }else{
          tmp[i.station_name.trim()] = "<p>站点名："+i.station_name+"</p><p>"+i.transfer_line+"：</p><p style='text-indent:30px;'>换入客量："+i.in_amount+"</p>"+"<p style='text-indent:30px;'>运能："+i.capacity+"</p><p style='text-indent:30px;'>综合匹配比："+i.ratio+"</p>";
        }
      }
      console.log(tmp);
      return tmp;
    }
  },
  methods: {
    openD() {
      this.modalSwitch = true;
    },
    get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/ask_for_passenger", {
          userToken: userToken,
        })
        .then((response) => {
          this.passenger = response.data.res_record;
        });
    },
    get_station() {
      this.$axios
        .post(this.$url + '/auth_interface', {
          page: {
            currentPage: 1,
            currentSize: 200
          },
          askData:'transferList',
          choosedOptions:{selectedList:[],keyword:''},
          userToken: userToken
        })
        .then(
          response => {
            this.stationData = response.data.res_record;
            console.log(this.stationData);
          }
        );
    },
    colorCal(value) {
      if (value < 50) {
        return "#5cb85c";
      } else if (value < 75) {
        return "#a9e723";
      } else if (value < 100) {
        return "#fee901";
      } else {
        return "#fe1901";
      }
    },
    percentCal(value) {
      if (value > 100) {
        return 100;
      } else {
        return value;
      }
    },
  },
  created() {
    // this.get_data();
    this.get_station();
  },
};
</script>
<style scoped>
.outer-wrapper {
  position: relative;
  min-height: 640px;
  width: 100%;
  border: 1px solid #aaa;
}
.img-wrapper {
  position: absolute;
  width: 600px;
}

.img-wrapper p {
  text-align: center;
  text-indent: 36px;
  position: absolute;
  top: 30px;
  width: 400px;
  padding: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.img-item {
  width: 400px;
  cursor: pointer;
}

p {
  line-height: 18px;
  font-size: 12px;
}

.img-bb{
  background: url(../../../public/img/flow/main.png) no-repeat;
  background-size:100% 100%;
  position: relative;
  width:100%;
  height:900px;
  overflow: visible;
  margin-top:40px;
}

.station-wrapper{
  position: absolute;
}

.station-inner-wrapper{
  position: relative;
}

.station-wrapper img{
  position: relative;
  width:22%;
  transform: scale(1.3);
  z-index:800;
}


.station-wrapper img:hover{
  width:22%;
  cursor: pointer;
  transform: scale(1.6);
}

.html-wrapper{
  position: absolute;
  display: none;
  z-index: 1000;
  width:300px;
  background-color: rgb(103, 170, 123);
  padding: 20px 30px;
  color:#fff;
  font-size: 18px;
  border-radius: 3px;
  font-family: '黑体';
}

.station-inner-wrapper>img:hover+.html-wrapper{
  display: block;
}

</style>